<template>
  <div v-if="permissions.u">
    <button-top v-if="permissions.u" :must-icon="false" tooltip="Guardar especie" name="Guardar" :date="form.created_at"
      @action="update"></button-top>

    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Nombre</label>
              <validation-provider #default="{ errors }" name="Nombre" rules="required">
                <b-form-input id="name" v-model="form.name" :state="errors.length > 0 ? false : null" name="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="country_id" class="text-success">Pais</label>
              <validation-provider #default="{ errors }" name="Pais" rules="required">
                <b-form-select id="country_id" class="fl" v-model="form.country_id" @change="getDepartments()"
                  value-field="id" text-field="name" :options="list.countries" :state="errors.length > 0 ? false : null"
                  name="country_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="department_id" class="text-success">Departamento</label>
              <validation-provider #default="{ errors }" name="Departamento" rules="required">
                <b-form-select id="department_id" class="fl" v-model="form.department_id" @change="getCities()"
                  value-field="id" text-field="name" :options="list.departments" :state="errors.length > 0 ? false : null"
                  name="department_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="city_id" class="text-success">Municipio</label>
              <validation-provider #default="{ errors }" name="Municipio" rules="required">
                <b-form-select id="city_id" class="fl" v-model="form.city_id" value-field="id" text-field="name"
                  @change="getBasins()" :options="list.cities" :state="errors.length > 0 ? false : null"
                  name="city_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="latitude" class="text-success">Latitud</label>
              <validation-provider #default="{ errors }" name="Latitud" rules="required">
                <b-form-input id="latitude" v-model="form.latitude" :state="errors.length > 0 ? false : null"
                  name="latitude" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="longitude" class="text-success">Longitud</label>
              <validation-provider #default="{ errors }" name="Longitud" rules="required">
                <b-form-input id="longitude" v-model="form.longitude" :state="errors.length > 0 ? false : null"
                  name="longitude" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="basin_id" class="text-success">Cuenca</label>
              <validation-provider #default="{ errors }" name="Cuenca" rules="required">
                <b-form-select id="basin_id" class="fl" v-model="form.basin_id" value-field="id" text-field="name"
                  :options="list.basins" :state="errors.length > 0 ? false : null" name="basin_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Observacion</label>
              <b-form-textarea id="observation" v-model="form.observation" name="observation" rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        id: "",
        name: "",
        country_id: "",
        department_id: "",
        city_id: "",
        latitude: "",
        longitude: "",
        basin_id: "",
        observation: "",
      },

      list: {
        countries: [],
        departments: [],
        cities: [],
        basins: [],
      },
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.form.id = this.$route.params.id;
      await this.getPermission("u");
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.form = (
          await this.$http.get(`administration/land/show/${this.form.id}`)
        ).data.data;
        this.setHeaderSubTitle(
          `Id. ${this.form.id} / ${this.form.name}`
        );

        this.list.countries = (
          await this.$http.get("configuration/country/index")
        ).data.data;
        this.list.departments = (
          await this.$http.get(
            `configuration/department/show/${this.form.country_id}`
          )
        ).data.data;
        this.list.cities = (
          await this.$http.get(
            `configuration/city/show/${this.form.department_id}`
          )
        ).data.data;

        this.list.basins = (
          await this.$http.get("administration/integration/basin/index", {
            params: {
              no_paginate: true,
              city_id: this.form.city_id,
            },
          })
        ).data.data;

      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: "usuarios" });
        }
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async getCountries() {
      try {
        this.loading();
        this.list.countries = (
          await this.$http.get("configuration/country/index")
        ).data.data;
      } catch (err) {
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async getDepartments() {
      try {
        this.loading();
        this.list.departments = (
          await this.$http.get(
            `configuration/department/show/${this.form.country_id}`
          )
        ).data.data;
        this.form.department_id = "";
        this.form.city_id = "";
        this.form.basin_id = "";
      } catch (err) {
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async getCities() {
      try {
        this.loading();
        this.list.cities = (
          await this.$http.get(
            `configuration/city/show/${this.form.department_id}`
          )
        ).data.data;
        this.form.city_id = "";
        this.form.basin_id = ""
      } catch (err) {
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async getBasins() {
      try {
        this.loading();
        this.list.basins = (
          await this.$http.get("administration/integration/basin/index", {
            params: {
              no_paginate: true,
              city_id: this.form.city_id,
            },
          })
        ).data.data;
        this.form.basin_id = ""
      } catch (err) {
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    update() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading();
          this.$http
            .patch(`administration/land/update/${this.form.id}`, this.form)
            .then((res) => {
              this.notify(
                "Proceso satisfactorio.",
                res.data.message,
                "primary"
              );
              this.$router.push(`/gestion-predios/show/${this.form.id}`);
            })
            .catch((err) => {
              this.notify("Error", err.response.data.message, "danger");
            })
            .finally(() => {
              this.not_loading();
            });
        }
      });
    },
  },
};
</script>